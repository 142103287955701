import constants from '../constants';

const drawerItems = [
  {
    module: constants.MODULE_DASHBOARD,
    icon: 'mdi-view-dashboard',
    title: 'dashboard',
    to: '/pages/dashboard',
  },
  {
    module: constants.MODULE_USER_PROFILE,
    icon: 'mdi-account',
    title: 'user',
    to: '/user',
  },
  {
    module: constants.MODULE_MEDIA_LIBRARY,
    icon: 'mdi-folder-multiple-image',
    title: 'Media Library',
    to: '/banner/medialibrary',
  },
  {
    module: constants.MODULE_BANNER,
    title: 'Banners',
    icon: 'mdi-clipboard-outline',
    to: '/banners',
  },
  {
    module: constants.MODULE_CAMPAIGN,
    title: 'Campaigns',
    icon: 'mdi-bullhorn',
    to: '/campaigns',
  },
  {
    module: constants.MODULE_DIGIBOARD,
    title: 'Devices',
    icon: 'mdi-television',
    to: '/digi-board',
  },
  {
    module: constants.MODULE_SWIFT_CHECK_IN,
    title: 'Swift Check-in',
    icon: 'mdi-clock-check-outline',
    group: '',
    children: [
      {
        title: 'Record List',
        to: 'swift-checkin/records',
      },
      {
        title: 'Project List',
        to: 'swift-checkin/projects',
      },
      {
        title: 'Settings',
        to: 'swift-checkin/settings',
      },
    ],
  },
  {
    module: constants.MODULE_REPORT,
    title: 'Reports',
    icon: 'mdi-chart-bar',
    to: '/reports',
  },
  {
    module: constants.MODULE_SOCIAL_MEDIA,
    title: 'Social Media',
    icon: 'mdi-youtube-tv',
    to: '/social-media',
  },
  {
    module: constants.MODULE_WIDGETS,
    title: 'Widgets',
    icon: 'mdi-apps',
    to: '/widgets',
  },
  {
    module: constants.MODULE_SUPPORT,
    title: 'Support',
    icon: 'mdi-face-agent',
    to: '/support',
  },
];

export default drawerItems;
